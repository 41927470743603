import { createSlice } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import Sentry from 'src/utils/sentry'

const checklistSlice = createSlice({
  name: 'checklist',
  initialState: {
    venueSupplier: null,
    approvedOrders: [],
    noApprovedOrders: [],
    updatedOrders: [],
    locked: false,
    orderError: false,
    isLoading: false,
    PONumbersPerDay: [],
  },
  reducers: {
    setOrders: (state, action) => {
      const {
        venueSupplier,
        approvedOrders,
        noApprovedOrders,
        PONumbersPerDay,
      } = action.payload
      state.approvedOrders = approvedOrders
      state.noApprovedOrders = noApprovedOrders
      state.PONumbersPerDay = PONumbersPerDay
      if (state.venueSupplier === null) state.venueSupplier = venueSupplier
    },
    setOrdersUpdated: (state, action) => {
      state.updatedOrders = action.payload
    },
    lock: state => {
      state.locked = true
    },
    setError: (state, action) => {
      state.orderError = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const { setOrders, lock, setError, setOrdersUpdated, setIsLoading } =
  checklistSlice.actions

export const fetchOrdersChecklist =
  (venueId, supplierId, date, orderNumber = 'all', noShowLoading = false) =>
  async dispatch => {
    try {
      if (!noShowLoading) dispatch(setIsLoading(true))
      const data = await API.get(
        'api',
        `checklist/${venueId}/${supplierId}${
          date ? `/${date}` : ''
        }/${orderNumber}`,
      )
      dispatch(setOrders(data))
      dispatch(setIsLoading(false))
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      dispatch(setIsLoading(false))
      return false
    }
  }

export const lockOrder =
  (venueId, supplierId, orders, orderError) => async dispatch => {
    try {
      const data = await API.post('api', `checklist/${venueId}/${supplierId}`, {
        body: orders,
      })
      dispatch(setOrdersUpdated(data))
      if (orderError) {
        dispatch(setError(false))
      }
      dispatch(lock())
    } catch (e) {
      dispatch(lock())
      dispatch(setError(true))
      console.error(e)
      Sentry.captureException(e)
      return false
    }
  }
export const notifications = async (venueId, supplierId) => {
  try {
    await API.post('api', `emailAndPushNotification`, {
      body: {
        venueId,
        supplierId,
      },
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

export const supplierNotifications = async (venueId, supplierId) => {
  try {
    await API.post('api', `emailAndPushNotification`, {
      body: {
        venueId,
        supplierId,
      },
      queryStringParameters: {
        isSupplierMessage: true,
      },
    })
  } catch (e) {
    console.log(e)
    return false
  }
}

export default checklistSlice
